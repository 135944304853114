import http from './request';

// 查询轮播图
export function queryCarousels (params) {
  return http({
    url: '/service/query/queryCarousels',
    method: 'get',
    params
  })
}
export function queryTitleList (params) {
  if (params.pageNum) {
    params.pageNum = params.pageNum - 1
  }
  return http({
    url: '/service/query/queryTitleList',
    method: 'get',
    params
  })
}
// 文章详情 did documentType
export function queryDetails (params) {
  return http({
    url: '/service/query/queryDetails',
    method: 'get',
    params
  })
}
// 配置内容查询
export function queryConfigInfo (params) {
  return http({
    url: '/service/query/queryConfigInfo',
    method: 'get',
    params
  })
}
export function querySubMenus (params) {
  return http({
    url: '/service/query/querySubMenus',
    method: 'get',
    params
  })
}
export function querySubMenusAll (params) {
  return http({
    url: '/service/query/querySubMenusAll',
    method: 'get',
    params
  })
}
// 模糊查询
export function blurryQuery (params) {
  if (params.pageNum) {
    params.pageNum = params.pageNum - 1
  }
  return http({
    url: '/service/query/blurryQuery',
    method: 'get',
    params
  })
}