<template>
  <header>
    <div class="flex main">
      <div class="logo-box">
        <a href="#" class="net-ico">
          <img class="logo" src="@/assets/logo.png" alt="">
        </a>
        <h1>国家中医药管理局中医药传统知识保护研究中心</h1>
      </div>
      <el-input class="header-search" placeholder="请输入关键字检索" suffix-icon="el-icon-search"
        @keyup.enter.native="querySearch" v-model="keywords">
      </el-input>
    </div>
  </header>
</template>

<script>

export default {
  data () {
    return {
      keywords: ""
    };
  },
  created () { },
  methods: {
    querySearch () {
      if(this.keywords){
        this.$router.push('/search/'+this.keywords+'?t='+new Date().getTime())
      }else{
        // this.$message.warning('请输入关键字检索')
      }
    }
  },
  components: {}
};
</script>

<style scoped lang="scss">
.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  box-sizing: border-box;
}
header {
  height: 125px;
  background: url("../assets/header_bg.png");
  h1 {
    color: #015288;
    margin-left: 20px;
    font-family: "华文琥珀";
    font-weight: lighter;
  }
  .logo-box {
    display: flex;
    align-items: center;
  }
  .net-ico {
    .logo {
      width: 100px;
      height: 100px;
      vertical-align: middle;
    }
  }
  .header-search {
    width: 205px;
    height: 40px;
    /deep/ .el-input__inner {
      border-color: #005389;
    }
  }
}
</style>
