import dayjs from 'dayjs'

// 全局使用中文
dayjs.locale('zh-cn')

// 对时间进行格式化
export function formatTime (data = new Date(), type = 'YYYY-MM-DD') {
  return dayjs(data).format(type)
}


export default {
  formatTime
}

