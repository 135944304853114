<template>
  <div class="nav">
    <div class="main">
      <el-menu :default-active="activeIndex" router class="el-menu-demo" mode="horizontal" @select="handleSelect"
        background-color="#005389" text-color="#fff" active-text-color="#ffd04b">
        <el-menu-item index="/">首页</el-menu-item>
        <el-submenu index="2">
          <template slot="title">中心概况</template>
          <el-menu-item index="/survey/1">中心简介</el-menu-item>
          <el-menu-item index="/survey/2">中心领导</el-menu-item>
          <el-menu-item index="/survey/3">机构设置</el-menu-item>
          <el-menu-item index="/survey/4">联系我们</el-menu-item>
        </el-submenu>
        <el-menu-item index="/notice/1">通知公告</el-menu-item>
        <el-menu-item index="/notice/3">政策法规</el-menu-item>
        <el-menu-item index="/notice/2">工作动态</el-menu-item>
        <!-- <el-submenu index="7">
          <template slot="title">科学研究</template>
          <el-menu-item v-for="(item,o) in kxList" :key="o" :index="'/scientific/list/'+item.configType">
            {{item.configContent}}
          </el-menu-item>
        </el-submenu> -->
        <el-submenu index="/scientific/list'">
          <template slot="title">科学研究</template>
          <template v-for="item in kxList">
            <el-submenu v-show="item.menuVos&&item.menuVos.length>0" :index="'kx'+item.id" :key="'kx'+item.id">
              <template slot="title">{{item.configContent}}</template>
              <template v-for="sitem in item.menuVos">
                <el-menu-item :key="'kx-'+item.id+'-'+sitem.id" :index="`/scientific/list/${sitem.configType}`">
                  <el-link v-if="sitem.configUrl" :href="sitem.configUrl" target="_blank">{{sitem.configContent}}
                  </el-link>
                  <span v-else>{{sitem.configContent}}</span>
                </el-menu-item>
              </template>
            </el-submenu>
          </template>
        </el-submenu>
        <el-submenu index="">
          <template slot="title">平台入口</template>
          <el-menu-item index="">
            <el-link href="https://livedata.ptktcm.com" target="_blank">活态中医药传统知识数据库系统</el-link>
          </el-menu-item>
          <el-menu-item index="">
            <el-link href="https://treatment.ptktcm.com" target="_blank">民间中医特色诊疗技术信息管理系统</el-link>
          </el-menu-item>
        </el-submenu>
      </el-menu>
    </div>
    <div class="nav_btn" @click="drawer = true"><span></span></div>
    <el-drawer title="" size="80%" :visible.sync="drawer" direction="ltr">
      <template slot="title">
        <div>
          <img style="width: 70px;" src="@/assets/logo.png" alt="">
        </div>
      </template>
      <div class="m-menu-list">
        <el-menu router :unique-opened="true" class="el-menu-demo-vertical">
          <el-menu-item index="/">首页</el-menu-item>
          <el-submenu index="2">
            <template slot="title">中心概况</template>
            <el-menu-item index="/survey/1">中心简介</el-menu-item>
            <el-menu-item index="/survey/2">中心领导</el-menu-item>
            <el-menu-item index="/survey/3">机构设置</el-menu-item>
            <el-menu-item index="/survey/4">联系我们</el-menu-item>
          </el-submenu>
          <el-menu-item index="/notice/1">通知公告</el-menu-item>
          <el-menu-item index="/notice/3">政策法规</el-menu-item>
          <el-menu-item index="/notice/2">工作动态</el-menu-item>
          <el-submenu index="/scientific/list'">
            <template slot="title">科学研究</template>
            <template v-for="item in kxList">
              <el-submenu v-show="item.menuVos&&item.menuVos.length>0" :index="'kx'+item.id" :key="'kx'+item.id">
                <template slot="title">{{item.configContent}}</template>
                <template v-for="sitem in item.menuVos">
                  <el-menu-item :key="'kx-'+item.id+'-'+sitem.id" :index="`/scientific/list/${sitem.configType}`">
                    <el-link v-if="sitem.configUrl" :href="sitem.configUrl" target="_blank">{{sitem.configContent}}
                    </el-link>
                    <span v-else>{{sitem.configContent}}</span>
                  </el-menu-item>
                </template>
              </el-submenu>
            </template>
          </el-submenu>
          <!-- <el-submenu index="7">
            <template slot="title">科学研究</template>
            <el-menu-item v-for="(item,o) in kxList" :key="o" :index="'/scientific/list/'+item.configType">
              <template slot="title"> {{item.configContent}}</template>
              <el-submenu index="7">
                <el-menu-item index="2-4-1">选项1</el-menu-item>
                <el-menu-item index="2-4-2">选项2</el-menu-item>
                <el-menu-item index="2-4-3">选项3</el-menu-item>
              </el-submenu>
            </el-menu-item>
          </el-submenu> -->
          <el-submenu index="">
            <template slot="title">平台入口</template>
            <el-menu-item index="">
              <el-link href="https://livedata.ptktcm.com" target="_blank">活态中医药传统知识数据库系统</el-link>
            </el-menu-item>
            <el-menu-item index="">
              <el-link href="https://treatment.ptktcm.com" target="_blank">民间中医特色诊疗技术信息管理系统</el-link>
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { querySubMenusAll } from '@/api/index'
export default {
  data () {
    return {
      activeIndex: this.$route.path,
      drawer: false,
      kxList: []
    };
  },
  created () {
    querySubMenusAll({
      fatherMenuType: 'KX'
    }).then(res => {
      this.kxList = res.data.data
      console.log(this.kxList);
    })
  },
  mounted () {
    // this.activeIndex = this.$route.path
    // console.log(this.activeIndex)
  },
  watch: {
    $route (e) {
      this.activeIndex = e.path;  // e里面的是当前路由的信息
      this.drawer = false
    },
  },
  methods: {
    handleSelect (key, keyPath) {
      console.log(key, keyPath);
    }
  },
  components: {}
};
</script>

<style  lang="scss" >
.nav {
  background: #005389;
  .el-menu.el-menu--horizontal {
    border: none;
  }
}
.el-link.el-link--default {
  color: #fff !important;
}
.m-menu-list {
  .el-link.el-link--default {
    color: #303133 !important;
  }
}
@media screen and (max-width: 768px) {
  .nav .el-menu.el-menu--horizontal {
    display: none;
  }
  header h1 {
    display: none;
  }
  .right-breadcrumb {
    display: none !important;
  }
  header .net-ico .logo {
    width: 45px !important;
    height: 45px !important;
    display: none;
  }
  header .flex.main {
    justify-content: flex-start;
    // padding-left: 13px;
  }
  .el-tabs__content {
    h1 {
      font-size: 18px;
    }
    .article-content {
      width: 95%;
      margin: 0 auto;
      p {
        font-size: 14px;
        line-height: 26px;
        img {
          width: 50%;
        }
      }
    }
    .lxdh {
      text-align: center;
      img {
        width: 90%;
        height: auto !important;
      }
    }
  }
}
</style>
