
import axios from 'axios'
// 请求
const http = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 6000
})

// 请求拦截
http.interceptors.request.use(config => {
  //请求头设置
  return config
}, err => {
  console.log(err);
})
// 响应拦截
http.interceptors.response.use(response => {
  // 对响应码的处理

  return response
}, err => {
  console.log(err);
})
// 返出
export default http