import 'babel-polyfill'
import promise from 'es6-promise'
promise.polyfill()
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { formatTime } from './utils/formate'

Vue.prototype.$formatTime = formatTime
// 过滤器
Vue.filter('formatTime', formatTime)

import {
  Button,
  Input,
  Menu,
  Submenu,
  MenuItem,
  MenuItemGroup,
  Link,
  Carousel,
  CarouselItem,
  Image,
  Icon,
  Tabs,
  TabPane,
  Breadcrumb,
  BreadcrumbItem,
  Message,
  Loading,
  Pagination,
  Empty,
  Drawer
} from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'
import '@/styles/element-variables.scss'
Vue.use(Button)
Vue.use(Input)
Vue.use(Menu)
Vue.use(Submenu)
Vue.use(MenuItem)
Vue.use(MenuItemGroup)
Vue.use(Link)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Image)
Vue.use(Icon)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Pagination)
Vue.use(Empty)
Vue.use(Drawer)
Vue.use(Loading.directive);
Vue.prototype.$loading = Loading.service;
Vue.prototype.$message = Message;
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
