<template>
  <div id="app">
    <headerA />
    <navA />
    <router-view />
    <copyRight />
  </div>
</template>
<script>
// @ is an alias to /src
import headerA from '@/components/header.vue'
import navA from '@/components/nav.vue'
import copyRight from '@/components/copyright.vue'

export default {
  name: 'app',
  components: {
    headerA, navA, copyRight
  }
}
</script>
<style lang="scss">
@import url("./styles/index.scss");
</style>
