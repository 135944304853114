<template>
  <div>
    <div class="friend-link">
      <ul>
        <li>
          <img src="@/assets/friend_link_icon.png" alt="">
          <span>友情链接</span>
        </li>
        <li class="block">
          <el-link href="http://www.satcm.gov.cn/" target="_blank">国家中医药管理局</el-link>
        </li>
        <li class="block">
          <el-link href="https://www.cnipa.gov.cn/" target="_blank">国家知识产权局</el-link>
        </li>
        <li class="block">
          <el-link href="https://www.cacms.ac.cn/" target="_blank">中国中医科学院</el-link>
        </li>
        <li class="block">
          <el-link href="http://www.zgyswxyjs.cn/" target="_blank">中国中医科学院中国医史文献研究所</el-link>
        </li>
      </ul>
    </div>
    <footer>
      <div class="footer-top">
        <span style="margin-right:20px"> <i class="el-icon-location-outline"></i>
          {{copyRight.address}}</span>
        <span style="margin-right:20px"> <i class="el-icon-phone"></i>
         {{copyRight.phone}}</span>
        <span><i class="el-icon-message"></i>
          {{copyRight.email}}</span>
      </div>
      <div class="footer-bottom">
        <span>Copyright© 2022 国家中医药管理局中医药传统知识保护研究中心</span>
        <span style="margin-left:20px">京ICP备12038572号-3</span>
      </div>
    </footer>
  </div>
</template>

<script>
import { queryConfigInfo } from '@/api/index'
export default {
  data () {
    return {
      input1: "",
      copyRight:{}
    };
  },
  created () {
    this.getCopyRight()
  },
  methods: {
    getCopyRight () {
      queryConfigInfo({ type: 4 }).then(res => {
        this.copyRight = JSON.parse(res.data.data.configContent)
      })
    }
  },
  components: {}
};
</script>

<style scoped lang="scss">
footer {
  background: #075c9f;
  color: #fff;
  text-align: center;
  font-size: 14px;
  .footer-top {
    padding: 20px 0;
  }
  .footer-bottom {
    padding: 0px 0 20px 0;
  }
}
.friend-link {
  padding: 15px 0;
  background: #f7f7f7;
  text-align: center;
  ul {
    margin: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  li {
    padding: 0 20px;
    position: relative;
    font-size: 14px;
    font-weight: bold;
    img {
      width: 20px;
      height: 20px;
      vertical-align: middle;
      margin-top: -3px;
      margin-right: 5px;
    }
  }
  .block::before {
    content: "";
    width: 5px;
    height: 5px;
    background: #c1c1c1;
    position: absolute;
    left: 9px;
    top: 10px;
  }
  .el-link.el-link--default {
    color: #075c9f !important;
    font-weight: bold;
  }
}
</style>
