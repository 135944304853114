import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path: '/survey/:type',
    name: 'Survey',
    component: () => import('../views/Survey.vue')
  },
  {
    path: '/notice/:id',
    name: 'Notice',
    component: () => import('../views/Notice.vue')
  },
  {
    path: '/search/:keywords',
    name: 'search',
    component: () => import('../views/Notice.vue')
  },
  {
    path: '/scientific',
    name: 'Scientific',
    component: () => import('../views/scientific/index.vue'),
    children: [{
      path: 'list/:id',
      name: '列表',
      component: () => import('../views/scientific/list.vue'),
    }, {
      path: 'info',
      name: '详情',
      component: () => import('../views/scientific/info.vue'),
    }
    ]
  },
  {
    path: '/editor/:id',
    name: 'Editor',
    component: () => import('../views/Editor.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
